<template>
  <b-modal
    id="editarDespesa"
    no-close-on-backdrop
    no-close-on-esc
    centered
    hide-footer
    size="xl"
    title="Editar Despesa"
  >
    <b-card>
      <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show>
        <div
          class="alert-body"
          v-for="(error, index) in getErrors.messageArray"
          :key="index"
        >
          {{ error[0] }}
        </div>

        <div class="alert-body">{{ getErrors.message }}</div>
      </b-alert>
      <validation-observer ref="despesaCadastroValidation">
        <b-row>
          <!--  DATA VENCIMENTO -->
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Data de Vencimento"
              rules="required"
            >
              <b-form-group label="Data de Vencimento" label-for="compra">
                <b-form-datepicker
                  id="compra"
                  v-model="editar.data_vencimento"
                  :date-format-options="{
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                  }"
                  close-button
                  hide-header
                  label-close-button="Fechar"
                  label-reset-button="Apagar Data"
                  label-help=""
                  locale="pt-BR"
                  placeholder="Data"
                  reset-button
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!--  NOTA FISCAL / REFERÊNCIA -->
          <b-col>
            <b-form-group label="Nota Fiscal/Referência" label-for="referencia">
              <validation-provider name="Cód. Referência" rules="max:36">
                <b-form-input id="referencia" v-model="editar.referencia" />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!--  FORNECEDOR -->
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Fornecedor"
              rules="required"
            >
              <b-form-group label="Fornecedor" label-for="fornecedor">
                <v-select
                  style="background-color: white"
                  id="fornecedor"
                  v-model="editar.fornecedor_id"
                  :reduce="(fornecedores) => fornecedores.id"
                  label="fornecedor_nome"
                  :options="fornecedores"
                  @input="mudaCategoria"
                >
                </v-select>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!-- CATEGORIA -->
          <b-col>
            <b-form-group label="Categoria" label-for="planos">
              <validation-provider
                #default="{ errors }"
                name="Categoria"
                rules="required"
              >
                <v-select
                  v-model="editar.plano_id"
                  :options="despesasAgrupadas"
                  :reduce="(plano) => plano.id"
                  label="nome"
                  :selectable="(option) => option.posicao > 0"
                >
                  <template #list-header>
                    <li
                      v-b-toggle.sidebar-invoice-add-new-customer
                      class="
                        add-new-client-header
                        d-flex
                        align-items-center
                        my-50
                        ml-1
                        text-success
                      "
                      @click="cadastrarPlano"
                    >
                      <feather-icon
                        class="text-success"
                        icon="PlusIcon"
                        size="16"
                      />
                      <span class="align-middle ml-25"
                        >Cadastrar Categoria</span
                      >
                    </li>
                  </template>

                  <template #option="{ nome, posicao }">
                    <h5
                      v-if="posicao == 0"
                      class="text-primary"
                      style="margin: 0"
                    >
                      {{ nome }}
                    </h5>
                    <em v-else-if="posicao == 1" class="pl-2">{{ nome }}</em>
                    <em v-else class="pl-4"> - {{ nome }}</em>
                  </template>

                  <div slot="no-options">Categoria não encontrada!</div>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- DESCRIÇÃO -->
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Descrição"
              rules="required"
            >
              <b-form-group label="Descrição" label-for="descricao">
                <b-form-input id="descricao" v-model="editar.descricao" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!-- FORMA PAGAMENTO -->
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Forma Pagamento"
              rules="required"
            >
              <b-form-group
                label="Forma de Pagamento"
                label-for="formaPagamento"
              >
                <b-form-select
                  v-model="editar.forma_pagamento"
                  :options="formas"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <!-- VALOR -->
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Valor"
              rules="required|between:0,999999"
            >
              <b-form-group label="Valor" label-for="valor_parcela">
                <b-input-group prepend="R$">
                  <money
                    id="valor"
                    v-model="editar.total_bruto"
                    v-bind="money"
                    class="form-control"
                    @input="calculaTotal"
                  />
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!-- DESCONTO -->
          <b-col>
            <b-form-group label="Desconto" label-for="desconto">
              <b-input-group>
                <b-input-group-prepend>
                  <b-form-select
                    v-model="editar.tipo_desconto"
                    :options="optDesconto"
                    style="
                      border-top-right-radius: 0;
                      border-bottom-right-radius: 0;
                    "
                    text-field="tipo"
                    @change="calculaTotal"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-if="editar.tipo_desconto === 0"
                  v-model="editar.desconto"
                  v-bind="money"
                  :raw="false"
                  :state="errors.length > 0 ? false : null"
                  class="form-control"
                  @input="calculaTotal"
                />
                <money
                  v-else
                  id="valor"
                  v-model="editar.desconto"
                  v-bind="money"
                  class="form-control"
                  @input="calculaTotal"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- TOTAL -->
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Valor"
              rules="required|between:0,999999"
            >
              <b-form-group label="Total" label-for="valor_parcela">
                <b-input-group prepend="R$">
                  <money
                    id="valor"
                    v-model="editar.total_liquido"
                    v-bind="money"
                    class="form-control"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- REPETICOES -->
        <!-- <b-row>      
          <b-col lg="4">
            <b-form-group label="Repetições" label-for="repeticoes">
              <b-form-spinbutton
                id="repeticoes"
                v-model="repeticoes"
                class="w-50"
                inline
                max="60"
              />
            </b-form-group>
          </b-col>
        </b-row> -->
      </validation-observer>
      <b-row class="mt-2">
        <b-col>
          <b-button
            :disabled="submit"
            block
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="cadastrar"
            variant="success"
          >
            <template>
              <b-spinner v-if="spinner" small />
              <span v-if="spinner">Aguarde...</span>
              <span v-else>Confirmar Edição</span>
            </template>
          </b-button>
        </b-col>
        <b-col>
          <b-button
            block
            variant="outline-secondary"
            @click="$bvModal.hide('editarDespesa')"
          >
            Fechar
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BModal,
  BFormGroup,
  BFormDatepicker,
  BFormInput,
  BInputGroup,
  BFormSelect,
  BFormSpinbutton,
  BInputGroupPrepend,
  BSpinner,
  BAlert,
  VBToggle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import money from "v-money";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { min, max, required } from "@validations";
import { mapState, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formaPagamento from "../formaPagamento.js";
export default {
  directives: { "b-toggle": VBToggle },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    BFormGroup,
    BInputGroupPrepend,
    BFormDatepicker,
    BFormInput,
    BInputGroup,
    BFormSpinbutton,
    BSpinner,
    vSelect,
    BFormSelect,
    ValidationObserver,
    ValidationProvider,
    BAlert,
    ToastificationContent,
    VBToggle,
  },

  props: ["editar"],

  data() {
    return {
      errors: false,
      required,
      min,
      max,
      spinner: false,
      submit: false,
      optDesconto: [
        { value: 0, tipo: "%" },
        { value: 1, tipo: "R$" },
      ],
      ...formaPagamento,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      getErrors: {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
      },
    };
  },

  mounted() {
    this.calculaTotal();
  },

  computed: {
    ...mapState({
      fornecedores: (state) => state.fornecedores.fornecedores,
      planos: (state) => state.planos.planos,
    }),
    ...mapGetters("planos", ["planosDevedores", "despesasAgrupadas"]),
  },

  watch: {
    total_bruto() {
      this.calculaTotal();
    },
    desconto() {
      this.calculaTotal();
    },
  },

  methods: {
    calculaTotal() {
      if (this.editar.desconto > 0 && this.editar.tipo_desconto == 0) {
        this.editar.total_liquido =
          this.editar.total_bruto -
          this.editar.total_bruto * (this.editar.desconto / 100);
      } else if (this.editar.desconto > 0 && this.editar.tipo_desconto == 1) {
        this.editar.total_liquido =
          this.editar.total_bruto - this.editar.desconto;
      } else {
        this.editar.total_liquido = this.editar.total_bruto;
      }
    },

    mudaCategoria() {
      let plano;
      plano = this.fornecedores.filter(
        (forn) => forn.id == this.editar.fornecedor_id
      );
      plano.forEach((el) => (this.editar.plano_id = el.plano_id));
      return plano;
    },

    cadastrar() {
      this.$refs.despesaCadastroValidation.validate().then((success) => {
        if (success) {
          this.spinner = true;
          this.submit = true;
          this.errors = false;

          let payload = {
            ...this.editar,
            valor: this.editar.total_liquido
          }

          this.$http
            .put("adm/despesas/"+this.editar.id, payload)
            .then((res) => {
              if (res.data) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Despesa Editada com Sucesso!",
                    icon: "InfoIcon",
                    variant: "success",
                  },
                });
              }
              this.getErrors = false;
              this.$refs.despesaCadastroValidation.reset();
              this.$store.dispatch("despesas/listaDespesas");
              this.$bvModal.hide('editarDespesa')
            })
            .catch((error) => {
              let erro = this.$responseMessages.getMessage(error, null);
              this.getErrors = erro;
            })
            .finally(() => {
              this.spinner = false;
              this.submit = false;
            });
        }
      });
    },
    cadastrarPlano() {},

    resetaCampos() {
      (this.fornecedor_id = ""),
        (this.total_bruto = ""),
        (this.desconto = ""),
        (this.form = {
          plano_id: "",
          data_vencimento: this.$getDate.hoje(),
          referencia: "",
          descricao: "",
          total_liquido: "",
          forma_pagamento: "",
          tipo_desconto: 0,
        });
    },
  },
};
</script>

<style>
</style>