<template>
  <div>
    <b-row>
      <b-col md="2">
        <label> Status Despesa</label>
        <v-select
          id="tipo_despesa"
          v-model="tipo_despesa"
          :options="tipoDespesa"
          :reduce="(despesa) => despesa.value"
          label="text"
          class="select-size-sm"
          @input="alterouTipoDespesa"
        >
          <div slot="no-options">não encontrado!</div>
        </v-select>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Período"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="tipo"
              v-model="periodo"
              :options="periodos"
              text-field="text"
              value-field="value"
              style="height: 32px"
              @change="alterouPeriodo"
            >
            </b-form-select>
            <b-form-datepicker
              id="data_inicio"
              size="sm"
              v-model="data_inicio"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }"
              close-button
              weekday-header-format="narrow"
              no-flip
              dropdown
              hide-header
              label-close-button="Fechar"
              label-reset-button="Apagar Data"
              labelHelp=""
              locale="pt-BR"
              placeholder="Data"
              reset-button
              @input="alterouData"
            />
            <b-form-datepicker
              id="data_final"
              size="sm"
              v-model="data_final"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }"
              close-button
              hide-header
              weekday-header-format="narrow"
              no-flip
              dropdown
              label-close-button="Fechar"
              label-reset-button="Apagar Data"
              labelHelp=""
              locale="pt-BR"
              placeholder="Data"
              reset-button
              @input="alterouData"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Fornecedor" label-for="fornecedor" label-size="sm">
          <v-select
            style="background-color: white"
            id="fornecedor"
            v-model="form.fornecedor_id"
            :reduce="(fornecedores) => fornecedores.id"
            label="fornecedor_nome"
            class="select-size-sm"
            :options="fornecedores"
            @input="alterouFornecedor"
          />
          <div slot="no-options">não encontrado!</div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="despesasFiltradas"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :busy="busy"
          show-empty
        >
          <template #empty>
            <h4 class="text-center text-primary">
              nenhum resultado encontrado
            </h4>
          </template>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Aguarde...</strong>
            </div>
          </template>
          <template #cell(data_vencimento)="data">
            {{ data.item.data_vencimento | formataData }}
          </template>
          <template #cell(forma_pagamento)="data">
            {{ data.item.forma_pagamento | formatString }}
          </template>

          <template #cell(total_liquido)="data">
            <span v-if="data.item.data_pagamento">{{
              data.item.valor | formataMoeda
            }}</span>
            <span v-else>{{ data.item.total_liquido | formataMoeda }}</span>
          </template>

          <template #row-details="row">
            <b-card class="mb-0">
              <b-row>
                <b-col md="4">
                  <b>Categoria: </b> {{ row.item.categoria_nome }}
                </b-col>
                <b-col>
                  <b>Valor Bruto: </b> {{ row.item.total_bruto | formataMoeda }}
                </b-col>
                <b-col>
                  <b>Desconto: </b>
                  {{ row.item.tipo_desconto == 0 ? "% " : "R$ " }}
                  {{ row.item.desconto == null ? "0,00" : row.item.desconto }}
                </b-col>
                <b-col>
                  <b>Valor Líquido: </b>
                  {{ row.item.total_liquido | formataMoeda }}
                </b-col>
              </b-row>
              <div v-if="row.item.data_pagamento">
                <b-row class="mt-1">
                  <b-col md="4">
                    <b>Observação: </b> {{ row.item.observacao }}
                  </b-col>
                  <b-col>
                    <b>Data Pagamento: </b>
                    {{ row.item.data_pagamento | formataData }}
                  </b-col>
                  <b-col>
                    <b>Juros: </b> {{ row.item.juros | formataMoeda }}
                  </b-col>
                  <b-col>
                    <b>Valor Pago: </b> {{ row.item.valor | formataMoeda }}
                  </b-col>
                </b-row>
              </div>
              <div v-if="row.item.conta_nome">
                <b-row class="mt-1">
                  <b-col md="4">
                    <b>Conta: </b> {{ row.item.conta_nome }}
                  </b-col>
                </b-row>
              </div>

              <hr class="mt-2" />

              <b-button
                size="sm"
                variant="outline-secondary"
                @click="row.toggleDetails"
              >
                Fechar Detalhes
              </b-button>
            </b-card>
          </template>

          <template #cell(opcoes)="data">
            <b-button
              class="btn-icon"
              variant="flat-primary"
              style="padding: 2px"
              @click="pagar(data.item)"
              v-if="!data.item.data_pagamento"
            >
              <b-spinner v-if="spinner == data.item.id" small />
              <span v-if="spinner == data.item.id">Aguarde...</span>
              <b-badge v-else variant="primary" class="px-50">Pagar</b-badge>
            </b-button>
            <b-badge
              style="margin-right: 2px"
              class="px-75"
              :id="`pagto-${data.index}`"
              variant="light-success"
              v-else
            >
              Pago
            </b-badge>

            <b-popover
              v-if="data.item.data_pagamento"
              :target="`pagto-${data.index}`"
              placement="top"
              triggers="hover"
            >
              <template #title>
                {{ data.item.data_pagamento | formataData }}
              </template>
            </b-popover>
            <b-button
              class="btn-icon"
              variant="flat-secondary"
              @click="data.toggleDetails"
              v-model="data.detailsShowing"
              style="padding: 2px"
            >
              <feather-icon
                :class="data.detailsShowing ? 'text-primary' : 'text-default'"
                icon="EyeIcon"
                size="16"
              />
            </b-button>
            <b-button
              :disabled="data.item.data_pagamento ? true : false"
              class="btn-icon"
              variant="flat-secondary"
              @click="editarDespesa(data.item)"
              style="padding: 2px"
            >
              <feather-icon icon="Edit2Icon" size="16" />
            </b-button>

            <b-button
              v-if="data.item.data_pagamento"
              class="btn-icon text-danger"
              variant="flat-secondary"
              @click="restaurarDespesa(data.item)"
              style="padding: 2px"
            >
              <feather-icon icon="RefreshCwIcon" size="16" />
            </b-button>
            <b-button
              v-else
              class="btn-icon text-danger"
              variant="flat-secondary"
              @click="excluir(data.item, data.index)"
              style="padding: 2px"
            >
              <feather-icon icon="Trash2Icon" size="16" />
            </b-button>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    <modal-editar-despesa :editar="despesa" />
    <modal-pagar-despesa :despesa="despesa" />
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormDatepicker,
  BButton,
  BModal,
  VBModal,
  VBPopover,
  BPopover,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import moment from "moment";
import { mapState, mapGetters } from "vuex";
import ModalPagarDespesa from "./ModalPagarDespesa.vue";
import ModalEditarDespesa from "./ModalEditarDespesa.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormDatepicker,
    BButton,
    vSelect,
    BModal,
    VBPopover,
    BPopover,
    BSpinner,
    ModalPagarDespesa,
    ModalEditarDespesa,
  },
  directives: {
    "b-modal": VBModal,
  },

  props: ["despesas", "filter"],
  data() {
    return {
      despesa: {},
      tipo_despesa: 0,
      periodo: 3,
      data_inicio: this.$getDate.primeiroDiaMes(),
      data_final: this.$getDate.ultimoDiaMes(),
      spinner: false,
      form: {},
      perPage: 50,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterOn: [],
      fields: [
        {
          key: "data_vencimento",
          label: "Vencimento",
          thStyle: { width: "10%" },
          sortable: true,
        },
        { key: "fornecedor_nome", label: "Fornecedor", sortable: true },
        { key: "referencia", label: "Referência", sortable: true },
        { key: "descricao", label: "Descrição", sortable: true },
        { key: "forma_pagamento", label: "Forma Pag.", sortable: true },
        { key: "total_liquido", label: "Valor", sortable: true },
        { key: "opcoes", label: "Opções" },
      ],
      tipoDespesa: [
        { value: 0, text: "Todas" },
        { value: 1, text: "A Pagar" },
        { value: 2, text: "Pagas" },
      ],
      periodos: [
        { value: 0, text: "Mostrar Todos" },
        { value: 1, text: "Hoje" },
        { value: 2, text: "Esta Semana" },
        { value: 3, text: "Este mês" },
        { value: 4, text: "Próximos 30 dias" },
        { value: 5, text: "Outro Período" },
      ],
    };
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.despesas.length;
  },

  computed: {
    ...mapState({
      fornecedores: (state) => state.fornecedores.fornecedores,
      busy: (state) => state.despesas.busy,
    }),
    ...mapGetters({
      despesasGetters: "despesas/despesasGetters",
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    despesasFiltradas() {
      let result = this.despesas;

      if (this.tipo_despesa === 1) {
        return (result = this.despesas.filter(
          (res) => res.data_pagamento === null
        ));
      } else if (this.tipo_despesa === 2) {
        return (result = this.despesas.filter(
          (res) => res.data_pagamento !== null
        ));
      }
      return result;
    },
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    alterouTipoDespesa() {
      this.$store.commit(
        "despesas/STORE_DESPESAS_FILTRADAS",
        this.tipo_despesa
      );
    },

    alterouPeriodo() {
      if (this.periodo === 0) {
        this.data_inicio = "";
        this.data_final = "";
      } else if (this.periodo === 1) {
        this.data_inicio = this.$getDate.hoje();
        this.data_final = this.$getDate.hoje();
      } else if (this.periodo === 2) {
        this.data_inicio = moment().day(0).format("YYYY-MM-DD"); // domingo desta semana
        this.data_final = moment().day(6).format("YYYY-MM-DD"); // sábado desta semana
      } else if (this.periodo === 3) {
        this.data_inicio = moment().startOf("month").format("YYYY-MM-DD");
        this.data_final = moment().endOf("month").format("YYYY-MM-DD");
      } else if (this.periodo === 4) {
        this.data_inicio = this.$getDate.hoje();
        this.data_final = this.$getDate.addDias(null, 30);
      } else if (this.periodo === 5) {
        return;
      }
      let payload = {
        periodo: this.periodo,
        data_inicio: this.data_inicio,
        data_final: this.data_final,
        tipo_despesa: this.tipo_despesa,
      };
      this.$store.commit("despesas/STORE_DESPESAS_FILTRADAS_DATAS", payload);
      this.alterouTipoDespesa();
      this.$store.dispatch("despesas/listaDespesas");
    },

    alterouData() {
      let payload = {
        data_inicio: this.data_inicio,
        data_final: this.data_final,
      };
      this.$store.commit("despesas/STORE_DESPESAS_FILTRADAS_DATAS", payload);
      this.$store.dispatch("despesas/listaDespesas");
    },

    alterouFornecedor() {
      if (this.form.fornecedor_id === null) {
        this.form.fornecedor_id = 0;
      }
      this.$store.commit(
        "despesas/STORE_DESPESAS_FORNECEDOR",
        this.form.fornecedor_id
      );
      this.$store.dispatch("despesas/listaDespesas");
    },

    editarDespesa(item) {
      this.despesa = item;
      this.$bvModal.show("editarDespesa");
    },
    excluir(item, index) {
      this.$bvModal
        .msgBoxConfirm("Deseja realmente excluir essa despesa?", {
          title: "Excluir Despesa",
          size: "sm",
          okVariant: "primary",
          okTitle: "Sim",
          cancelTitle: "Não",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((valor) => {
          if (valor) {
            this.$store.commit("despesas/DELETAR_DESPESA", index);
            this.$http.delete("adm/despesas/excluir/" + item.id, item);
            this.$store.dispatch("despesas/listaDespesas");
          }
        });
    },
    pagar(item) {
      this.despesa = item;
      this.$bvModal.show("pagarDespesa");
    },

    restaurarDespesa(item) {
      console.log(item);
      this.$bvModal
        .msgBoxConfirm("Deseja realmente restaurar essa despesa?", {
          title: "Restaurar Despesa",
          size: "sm",
          okVariant: "primary",
          okTitle: "Sim",
          cancelTitle: "Não",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((valor) => {
          if (valor) {
            this.$http.post("adm/despesas/restaurar", item)
            .then((res) => {
              if (res.data) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Despesa Restaurada com Sucesso!",
                    icon: "InfoIcon",
                    variant: "success",
                  },
                });
                this.$store.dispatch("despesas/listaDespesas");
              }
            })
          }
        });
    }
  },
  filters: {
    formataMoeda(valor) {
      if (!valor) return "0,00";

      let vl = parseFloat(valor);
      return vl.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    formataData(data) {
      if (!data) return;

      let d = data.split("-");
      return `${d[2]}/${d[1]}/${d[0]}`;
    },
    formatString(str) {
      if (!str) return;

      if (str === 'debito_conta') return "débito em conta"
      return str;
    }
  },
};
</script>