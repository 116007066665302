<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <!-- CARDS-->
    <b-row class="">
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="FlagIcon"
          color="warning"
          :statistic="'R$ '+total_pagar"
          statistic-title="A Pagar"
          class="mb-0"
        />
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="AlertOctagonIcon"
          color="danger"
          :statistic="'R$ '+total_atrasados"
          statistic-title="Contas em Atraso"
          class="mb-0"
        >
          <b-col>
            <b-button
              class="btn-icon"
              variant="flat-primary"
              style="padding: 2px; margin-top: 22px"
            >
              <b-badge variant="primary">Ver</b-badge>
            </b-button>
          </b-col>
        </statistic-card-horizontal>
      </b-col>
      <b-col lg="4" sm="4">
        <statistic-card-horizontal
          icon="AlertTriangleIcon"
          color="success"
          :statistic="'R$ '+total_pago"
          statistic-title="Contas Pagas"
          class="mb-0"
        />
      </b-col>
    </b-row>
    <!-- BOTÕES -->
    <b-row class="my-1" align-h="between">
      <b-col cols="4" align-self="end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="gradient-success"
          size="sm"
          style="margin-right: 5px"
          @click="modalLancarDespesa"
        >
          <feather-icon icon="PlusIcon" class="mr-75" />
          Lançar Despesa
        </b-button>

        <b-button
          size="sm"
          type="submit"
          variant="gradient-primary"
          style="margin-right: 5px"
        >
          <feather-icon icon="PrinterIcon" />
        </b-button>

        <b-button size="sm" type="submit" variant="gradient-primary">
          <feather-icon icon="FileTextIcon" />
        </b-button>
      </b-col>
      <b-col md="4">
        <b-form-group label-for="filterInput" class="mb-0">
          <label
            ><feather-icon icon="SearchIcon"></feather-icon> Procurar</label
          >
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Limpar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-card>
      <!-- <MenuHorizontal/> -->
      <ListaDespesas :despesas="despesas" :filter="filter" />
      <ModalLancarDespesas/>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BInputGroupAppend,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BBadge,
  BAvatar,
  BButton,
  BRow,
  BCol,
  BCard,
  BModal,
  VBModal,
} from "bootstrap-vue";

import draggable from "vuedraggable";
import ListaDespesas from "./ListaDespesas.vue";
import Ripple from "vue-ripple-directive";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import { mapState, mapGetters } from "vuex";
import ModalLancarDespesas from "./ModalLancarDespesas.vue";

export default {
  components: {
    BFormInput,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    draggable,
    BButton,
    BRow,
    BCol,
    BCard,
    BModal,
    ListaDespesas,
    StatisticCardHorizontal,
    ModalLancarDespesas,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      mqShallShowLeftSidebar: true,
      filter: null,
    };
  },

  mounted() {
    this.$store.dispatch("despesas/listaDespesas");
    this.$store.dispatch("fornecedores/listaFornecedores");
    this.$store.dispatch("planos/listaPlanos");
  },
  computed: {
    ...mapState({
      despesas: (state) => state.despesas.despesas,
      total_pagar: (state) => state.despesas.totalAPagar,
      total_atrasados: (state) => state.despesas.totalAtrasados,
      total_pago: (state) => state.despesas.totalPagos,
    }),
    ...mapGetters({
      despesasGetters: "despesas/despesasGetters"
    }),
    despesasSoma() {
      let soma = this.despesas.reduce(
        (total, item) => total + Number(item.valor),
        0
      );
      return soma.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    },
  },
  methods: {
    modalLancarDespesa() {
      this.$bvModal.show("lancarDespesa");
    },
  },
  filters: {
    formataMoeda(valor) {
      if (!valor) return "0,00";

      let vl = parseFloat(valor);
      return vl.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    formataData(data) {
      if (!data) return;

      let d = data.split("-");
      return `${d[2]}/${d[1]}/${d[0]}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
